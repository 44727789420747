<template>
  <section class="primary mx-0 my-0 px-0 py-0">
    <v-row>
      <v-img
        class="max-height-img"
        :src="require('../../../../assets/images/kinderplus_ninatarea.webp')"
      />
    </v-row>
    <v-row class="text-center" justify="center" align="center">
      <v-col cols="12" md="7">
        <v-img
          class="mx-auto"
          :src="require('../../../../assets/images/klogooficial.png')"
          width="150"
          heigth="150"
        />
      </v-col>
      <!-- <v-col cols="12" md="7">
        <h2 class="white--text">¡Bienvenido!</h2>
      </v-col> -->
      <v-col cols="12" md="7">
        <v-text-field
          class="max-width mx-auto"
          label="Usuario"
          placeholder="Usuario"
          dark
          v-model="username"
        />
        <v-text-field
          v-model="password"
          class="max-width mx-auto"
          label="Contraseña"
          placeholder="Contraseña"
          dark
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
        />
        <div v-if="loginError" class="mt-n4">
          <small class="warning--text">Usuario o contraseña incorrectos</small>
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <v-btn
          color="white"
          tile
          :loading="loadingLogin"
          @click="login({ username: username, password: password })"
        >
          <span class="primary--text">Ingresar</span>
        </v-btn>
      </v-col>

      <v-col cols="12" md="7">
        <small class="white--text">
          ¿Olvidaste tu contraseña?
          <a class="warning--text" @click="openResetPassword">Recuperar</a>
        </small>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { APIUrl } from '@/api/axios-base'

export default {
  data() {
    const openResetPassword = () => {
      window.open(`${APIUrl}/password_reset`, '_blank')
    }
    return {
      username: null,
      password: null,
      showPass: false,
      openResetPassword

    };
  },
  computed: {
    ...mapState(['loginError', 'loadingLogin']),
  },
  created() {
    this.setIsLogin(true);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    ...mapActions(['login']),
    
  },
};
</script>

<style>
.max-width {
  max-width: 40%;
}
.max-height-img {
  height: 30vh !important;
}
</style>
